<template>
  <section id="faq">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2><span :class="(data.global['headerIcons']) ? 'pr-3' : 'pr-0'"><b-icon v-if="data.global['headerIcons']" icon="question-square" class="text-primary" aria-hidden="true"></b-icon></span>{{ data.json.title }}</h2>
          <p v-html="data.json['paragraph']"></p>
        </div>

        <div class="col-12 accordion" role="tablist">
          <b-card v-for="(question, index) in data.json['questions']" :key="index" no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle="`accordion-${index}`" variant="primary">{{  question.title }}</b-button>
            </b-card-header>
            <b-collapse :id="`accordion-${index}`" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <b-card-text>{{ question.body }}</b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FAQ',
  props: {

  },
  computed: {
    data () {
      const cJson = this.$webData.components.find((e) => e.name === 'Faq');
      return {
        json: cJson,
        global: this.$webData.global
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container {
  .row {
    padding-top: 0;
  }
}
</style>
